import React from 'react'

import PageLayer from '~components/PageLayer'
import SEO from '~components/SEO'
import { MastheadContact } from '~components/Masthead'
import TextBlock from '~components/TextBlock'
import Block from '~components/Block'
import Link from '~components/Link'
import { Cell } from '~styles/common'

import { breakLines } from '~utils/helpers'

import { useCraft } from './query'

const ContactTemplate = () => {
  const { entry, globals } = useCraft()

  const {
    contactEmail,
    contactNumber,
    officeAddress
  } = globals.companyDetails

  return (
    <PageLayer>
      <SEO
        title="Contact"
        metadata={entry.metadata}
      />
      <MastheadContact
        {...entry.masthead}
      />
      <Block>
        <Cell gridColumn="span 5">
          <h2>
            <Link underlined to={`mailto:${contactEmail}`}>
              {contactEmail}
            </Link>
            <br/>
            {contactNumber}
          </h2>
        </Cell>
        <Cell gridColumn={{ _: 'span 5', lg: '8 / span 5' }}>
          <h2>{breakLines(officeAddress)}</h2>
        </Cell>
        <Cell />
      </Block>
      <TextBlock
        collapseBottomPadding={true}
        backgroundColor="grey"
        heading="Business enquiries"
        body={entry.businessEnquiries.body}
        hidePaddingCell={true}
      />
      <Block
        bg="grey"
        collapseBottomPadding={true}
      >
        <Cell
          display={{ _: 'none', sm: 'block' }}
          gridColumn={{ sm: 'span 6', lg: 'span 7' }}
        />
        <Cell gridColumn={{ _: 'span 3', sm: 'span 4', lg: 'span 5' }}>
          <img
            width="100%"
            src={entry.businessEnquiries.image.url}
            alt={entry.businessEnquiries.image.title}
          />
        </Cell>
      </Block>
      <TextBlock
        backgroundColor="clay"
        heading="Work at Today"
        body={`
          <p>
            Head on over to the <a href="/careers">careers</a> section to see current job opportunities and what it’s like to work at Today
          </p>
        `}
      />
    </PageLayer>
  )
}

export default ContactTemplate
