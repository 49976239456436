import { flattenContent, staticSuperTable } from '~utils/mapping-filters'

const mapEntry = (entry) => {
  const masthead = staticSuperTable(entry.masthead)
  const metadata = staticSuperTable(entry.metadata)
  const businessEnquiries = staticSuperTable(entry.businessEnquiries)

  return {
    masthead: {
      ...masthead,
      image: masthead.image[0]
    },
    businessEnquiries: {
      ...flattenContent(businessEnquiries, 'body'),
      image: businessEnquiries.image[0] || {}
    },
    metadata
  }
}

export const mapCraft = (craft) => {
  return {
    ...craft,
    entry: mapEntry(craft.entry)
  }
}
